module.exports = [{
      plugin: require('/zeit/565ede24/node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://badabing.co"},
    },{
      plugin: require('/zeit/565ede24/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-106988088-5"},
    },{
      plugin: require('/zeit/565ede24/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bada Bing LLC","short_name":"bbingllc","start_url":"/","background_color":"#7c2121","theme_color":"#7c2121","display":"standalone","icon":"src/images/BadaBing-icon-black.png"},
    },{
      plugin: require('/zeit/565ede24/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/zeit/565ede24/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
